<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="receptorModal">
      <CForm>
        <CRow>
          <CCol :md="(this.form.rfc == 'XAXX010101000' && this.type == 'I') || (this.form.rfc == 'XEXX010101000')  ? 9 : 12">
            <CSelect
              label="Cliente:"
              :isValid="checkIfValid('customer_id')"
              :value.sync="$v.form.customer_id.$model"
              :options="customersOptions"
              @change="changeCustomer()"
            />
          </CCol>
          <CCol md="3" v-show="this.form.rfc == 'XAXX010101000' && this.type == 'I'">
            <CSelect
              label="Factura Global:"
              :value.sync="$v.form.is_global.$model"
              :options="globalOptions"
            />
          </CCol>
          <CCol md="3" v-show="this.form.rfc == 'XEXX010101000'">
            <CInput
                label="Reg. Id:"
                :lazy="false"
                :value.sync="$v.form.identifier.$model"
                placeholder=""
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CSelect
              label="Uso de CFDI:"
              :isValid="checkIfValid('uso_cfdi_code')"
              :value.sync="$v.form.uso_cfdi_code.$model"
              :options="usosOptions"
              @change="changeUsoCfdi"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CSelect
              label="Regimen Fiscal:"
              :isValid="checkIfValid('regimen_code')"
              :value.sync="$v.form.regimen_code.$model"
              :options="regimensOptions"
              @change="changeRegimen"
            />
          </CCol>
        </CRow>
        <CRow v-show="this.form.rfc != 'XAXX010101000'">
          <CCol md="6">
            <CInput
                label="Calle:"
                :lazy="false"
                :value.sync="$v.form.street.$model"
                placeholder=""
            />
          </CCol>
          <CCol md="3">
            <CInput
                label="No. Exterior:"
                :lazy="false"
                :value.sync="$v.form.exterior.$model"
                placeholder=""
            />
          </CCol>
          <CCol md="3">
            <CInput
                label="No. Interior:"
                :lazy="false"
                :value.sync="$v.form.interior.$model"
                placeholder=""
            />
          </CCol>
        </CRow>
        <CRow v-show="this.form.rfc != 'XAXX010101000'">
          <CCol md="6">
            <CInput
                label="Colonia:"
                :lazy="false"
                :value.sync="$v.form.colony.$model"
                placeholder=""
            />
          </CCol>
          <CCol md="6">
            <CInput
                label="Ciudad:"
                :lazy="false"
                :value.sync="$v.form.municipality.$model"
                placeholder=""
            />
          </CCol>
        </CRow>
        <CRow v-show="this.form.rfc != 'XAXX010101000'">
          <CCol md="4" v-show="this.form.rfc != 'XEXX010101000'">
            <CSelect
              label="Estado:"
              :value.sync="$v.form.state.$model"
              :options="statesOptions"
            />
          </CCol>
          <CCol :md="this.form.rfc != 'XEXX010101000' ? 4 : 8">
            <CSelect
              label="Pais:"
              :value.sync="$v.form.country.$model"
              :options="countriesOptions"
            />
          </CCol>
          <CCol md="4">
            <CInput
                label="Código Postal:"
                :lazy="false"
                :value.sync="$v.form.zipcode.$model"
                :isValid="checkIfValid('zipcode')"
                placeholder=""
                invalidFeedback="Este campo es requerido y debe ser un código postal válido."
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6" v-show="this.form.is_global">
            <CSelect
              label="Periodicidad:"
              :value.sync="$v.form.periodicity_global.$model"
              :options="periodicityOptions"
            />
          </CCol>
          <CCol md="3" v-show="this.form.is_global">
            <CSelect
              label="Ejercicio:"
              :value.sync="$v.form.year_global.$model"
              :options="yearOptions"
            />
            
          </CCol>
          <CCol md="3" v-show="this.form.is_global">
            <CSelect
              label="Mes:"
              :value.sync="$v.form.month_global.$model"
              :options="this.form.periodicity_global == '05' ? biMonthsOptions : monthsOptions"
            />
          </CCol>
        </CRow>
      </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import customers_ws from '../../../services/customers';
import general_ws from '../../../services/general';
import store from '../../../store'

import moment from 'moment';

export default { 
  name: 'ReceptorModal',
  props: {
    zipcode: '',
    type: '',
  },
  
  data () {
    return {
      receptorModal: false,
      title: "Editar Receptor", 
      color: "info",
      isEdit: false,
      form: {},
      submitted: false,
      customers: [],
      customersOptions: [],
      usos_cfdi: [],
      usosOptions: [],
      regimens: [],
      regimensOptions: [],
      states: [],
      statesOptions: [],
      countries: [],
      countriesOptions: [],
      globalOptions: [
        {label: 'No', value: false},
        {label: 'Sí', value: true}
      ],
      periodicityOptions: [
        {label: 'Diario', value: "01"},
        {label: 'Semanal', value: "02"},
        {label: 'Quincenal', value: "03"},
        {label: 'Mensual', value: "04"},
        {label: 'Bimestral', value: "05"}
      ],
      monthsOptions: [
        {label: 'Enero', value: "01"},
        {label: 'Febrero', value: "02"},
        {label: 'Marzo', value: "03"},
        {label: 'Abril', value: "04"},
        {label: 'Mayo', value: "05"},
        {label: 'Junio', value: "06"},
        {label: 'Julio', value: "07"},
        {label: 'Agosto', value: "08"},
        {label: 'Septiembre', value: "09"},
        {label: 'Octubre', value: "10"},
        {label: 'Noviembre', value: "11"},
        {label: 'Diciembre', value: "12"}
      ],
      biMonthsOptions: [
        {label: 'Enero - Febrero', value: "13"},
        {label: 'Marzo - Abril', value: "14"},
        {label: 'Mayo - Junio', value: "15"},
        {label: 'Julio - Agosto', value: "16"},
        {label: 'Septiembre - Octubre', value: "17"},
        {label: 'Noviembre - Diciembre', value: "18"},
      ],
      yearOptions: [],
    }
  },
  mounted: async function() {
    let response1 = await customers_ws.get(); 

    if(response1.type == "success"){
      this.customers = response1.data;
    }

    let response2 = await general_ws.getCfdiUsosByRegimen("616");

    if(response2.type == "success"){
      this.usos_cfdi = response2.data;
      this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
    }

    let response3 = await general_ws.getRegimens(); 

    if(response3.type == "success"){
      this.regimens = response3.data;
    }

    let response4 = await general_ws.getStates(); 

    if(response4.type == "success"){
      this.states = response4.data;
    }

    let response5 = await general_ws.getCountries(); 

    if(response5.type == "success"){
      this.countries = response5.data;
    }

    this.customersOptions = await this.$parseSelectOptionsOrdered(this.customers, "id", "rfc:name" , [{value: 0, label: 'XAXX010101000 - PÚBLICO EN GENERAL'}]);
    
    this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
    this.regimensOptions = await this.$parseSelectOptionsOrdered(this.regimens, "code", "code:name");

    this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name");
    this.countriesOptions = await this.$parseSelectOptionsOrdered(this.countries, "code", "name");

    this.yearOptions = [
      {label: moment().format("YYYY"), value: moment().format("YYYY")},
      {label: moment().subtract(1, 'years').format("YYYY"), value: moment().subtract(1, 'years').format("YYYY")}
    ];
  },
  methods: {
    async changeCustomer () {
      if(this.form.customer_id == 0){
        this.form.rfc = "XAXX010101000";
        this.form.name = "PÚBLICO EN GENERAL";

        this.form.zipcode = this.zipcode;
        this.form.uso_cfdi_code = "S01";
        this.form.uso_cfdi_name = "Sin Efectos Fiscales";
        this.form.regimen_code = "616";
        this.form.regimen_name = "Sin Obligaciones Fiscales";

        let response = await general_ws.getCfdiUsosByRegimen("616");

        if(response.type == "success"){
          this.usos_cfdi = response.data;
          this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
        }
      }
      else{
        let contributor = await this.$findElementById(this.customers, this.form.customer_id);
        let regimen = await this.$findElementByCode(this.regimens, contributor.regimen);
        
        let response = await general_ws.getCfdiUsosByRegimen(regimen.code);

        if(response.type == "success"){
          this.usos_cfdi = response.data;
          this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
        }

        let uso_cfdi = await this.$findElementByCode(this.usos_cfdi, contributor.uso_cfdi);

        if(uso_cfdi === undefined){
          uso_cfdi = await this.$findElementByCode(this.usos_cfdi, this.usosOptions[0].value);
        }
        
        //Consultar los usos de CFDI para el regimen
        //Consultar los regimenes para el tipo de persona (fisica y moral)

        this.form.rfc = contributor.rfc;
        this.form.name = contributor.name;
        this.form.zipcode = contributor.zipcode;
        
        this.form.uso_cfdi_code = uso_cfdi.code;
        this.form.uso_cfdi_name = uso_cfdi.name;

        this.form.regimen_code = regimen.code;
        this.form.regimen_name = regimen.name;

        this.form.identifier = contributor.identifier == undefined ? '' : contributor.identifier,

        this.form.street = contributor.street;
        this.form.exterior = contributor.exterior;
        this.form.interior = contributor.interior;
        this.form.colony = contributor.colony;
        this.form.municipality = contributor.municipality;
        this.form.state = contributor.state;
        this.form.country = contributor.country;
      }
    },
    async changeUsoCfdi () {
      let uso_cfdi = await this.$findElementByCode(this.usos_cfdi, this.form.uso_cfdi_code);

      this.form.uso_cfdi_code = uso_cfdi.code;
      this.form.uso_cfdi_name = uso_cfdi.name;
    },
    async changeRegimen () {
      let regimen = await this.$findElementByCode(this.regimens, this.form.regimen_code);

      //Consultar los usos de CFDI para el regimen
      let response = await general_ws.getCfdiUsosByRegimen(regimen.code);

      if(response.type == "success"){
        this.usos_cfdi = response.data;
        this.usosOptions = await this.$parseSelectOptionsOrdered(this.usos_cfdi, "code", "code:name");
      }

      let uso_cfdi = await this.$findElementByCode(this.usos_cfdi, this.form.uso_cfdi_code);

      if(uso_cfdi === undefined){
        uso_cfdi = this.usos_cfdi[0];
      }

      this.form.regimen_code = regimen.code;
      this.form.regimen_name = regimen.name;
    },
    openModal (data) {
      this.receptorModal = true;
      this.color = "info";
      this.title = 'Editar Receptor';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          customer_id: data.customer_id,
          rfc: data.rfc,
          name: data.name,
          uso_cfdi_code: data.uso_cfdi.code,
          uso_cfdi_name: data.uso_cfdi.name,
          regimen_code: data.regimen.code,
          regimen_name: data.regimen.name,
          identifier: data.identifier == undefined ? '' : data.identifier,
          street: data.street,
          exterior: data.exterior,
          interior: data.interior,
          colony: data.colony,
          municipality: data.municipality,
          state: data.state,
          country: data.country,
          zipcode: data.zipcode,
          is_global: data.is_global,
          periodicity_global: data.periodicity_global,
          year_global: data.year_global,
          month_global: data.month_global
      };
    },
    store () {
      this.$v.$touch();

      if(this.form.rfc == "XEXX010101000" && this.form.country == "MEX"){
        this.showToast("error", "El RFC XEXX010101000 solo debe ser utilizado cuando el país de residencia es diferente a México.");
      }
      else{
        if (this.isValid) {
          this.submitted = true;

          this.$emit("updateReceptor", this.form );

          this.closeModal();
        }
      }
    },
    closeModal () {
      this.receptorModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      customer_id: {
        required
      },
      uso_cfdi_code: {
        required
      },
      regimen_code: {
        required
      },
      identifier: false,
      street: false,
      exterior: false,
      interior: false,
      colony: false,
      municipality: false,
      state: false,
      country: false,
      zipcode: {
        required
      },
      is_global: false,
      periodicity_global: false,
      year_global: false,
      month_global: false
    }
  },
}
</script>