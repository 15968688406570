<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="relationModal"
    >
      <CRow>
        <CCol md="12">
          <CForm>
            <CRow>
              <CCol md="12">
                <CSelect
                  label="UUID del CFDI Relacionado:"
                  :isValid="checkIfValid('uuid')"
                  :value.sync="$v.form.uuid.$model"
                  :options="cfdisOptions"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CSelect
                  label="Tipo de Relación:"
                  :isValid="checkIfValid('relation_type')"
                  :value.sync="$v.form.relation_type.$model"
                  :options="relationTypesOptions"
                  :disabled="relationDisabled"
                />
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import general_ws from '../../../services/general';
import invoices_ws from '../../../services/invoices';

import moment from 'moment';

const today = new moment();
const today2 = new moment();

export default { 
  name: 'RelationModal',
  props: {
    relationDisabled: false,
    relation_type: {
      type: String,
      default: '04'
    },
    type: String
  },
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      relationModal: false,
      title: "Agregar CFDI Relacionado", 
      color: "info",
      isEdit: false,
      form: {},
      submitted: false,
      cfdis: [],
      cfdisOptions: [],
      relationTypes: [],
      relationTypesOptions: []
    }
  },
  mounted: async function() {
    let response1 = await invoices_ws.getUUIDsByType("XAXX010101000", this.type); 

    if(response1.type == "success"){
      this.cfdis = response1.data;

      this.cfdisOptions = await this.$parseSelectOptionsOrdered(this.cfdis, "uuid", "folio:uuid");
    }

    let response2 = await general_ws.getRelationTypes(); 

    if(response2.type == "success"){
      this.relationTypes = response2.data;

      this.relationTypesOptions = await this.$parseSelectOptionsOrdered(this.relationTypes, "code", "code:name");
    }
  },
  methods: {
    async addRelationModal (rfc, relations) {
      this.relationModal = true;
      this.color = "info";
      this.title = 'Agregar CFDI Relacionado';
      this.isEdit = false;
      this.submitted = false;

      let response1 = await invoices_ws.getUUIDsByType(rfc, this.type, {uuids: relations}); 

      if(response1.type == "success"){
        this.cfdis = response1.data;

        this.cfdisOptions = await this.$parseSelectOptionsOrdered(this.cfdis, "uuid", "folio:uuid");
      }

      this.form = {
        uuid: this.cfdis[0].uuid,
        relation_type: this.relation_type
      };
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;
        
        this.$emit("addRelation", this.form);

        this.closeModal();
      }
    },
    closeModal () {
      this.relationModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      uuid: {
        required
      },
      relation_type: {
        required
      }
    }
  },
}
</script>