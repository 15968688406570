<template>
    <CModal :title="title" color="danger" :show.sync="deleteModal">   
        <p>¿Estás seguro que deseas cancelar el CFDI <b>{{name}}</b>?</p>
        <CRow v-show="invoice.cfdi_id > 0">
          <CCol md="12">
            <CSelect
              label="Motivo de Cancelación:"
              :isValid="checkIfValid('reason')"
              :value.sync="$v.form.reason.$model"
              :options="cancelationReasonsOptions"
              @change="changeReason"
            />
          </CCol>
        </CRow>
        <CRow v-if="invoice.cfdi_id > 0 && form.reason == '01'">
          <CCol md="12">
            <CSelect
              label="Comprobante que sustituye al que se quiere cancelar:"
              :isValid="checkIfValid('relation')"
              :value.sync="$v.form.relation.$model"
              :options="uuidsOptions"
            />
          </CCol>
        </CRow>
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="deleteModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="deleteModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="destroy" color="danger">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"
import general_ws from '../../../services/general';
import invoices_ws from '../../../services/invoices';

export default {
  name: 'CancelationModal',
  props: {

  },
  data () {
    return {
      deleteModal: false,
      title: "Cancelar Documento",
      id: 0,
      invoice: false,
      name: 'Cancelar Documento',
      isEdit: false,
      form: {
        reason: '03',
        relation: ''
      },
      submitted: false,
      cancelationReasons: [],
      cancelationReasonsOptions: [],
      uuids: [],
      uuidsOptions: []
    }
  },
  mounted: async function() {
    let response1 = await general_ws.getCancelationReasons(); 

    if(response1.type == "success"){
      this.cancelationReasons = response1.data;

      this.cancelationReasonsOptions = await this.$parseSelectOptionsOrdered(this.cancelationReasons, "code", "code:name");
    }
  },
  methods: {
    show (title, invoice) {
      this.deleteModal = true;
      this.title = title;
      this.id = invoice.id;
      this.name = invoice.information.serial+""+invoice.information.folio;
      this.invoice = invoice;

      this.isEdit = false;
      this.submitted = false;
      
      this.form = {
        reason: '03',
        relation: ''
      };
    },
    async destroy () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;
        
        this.closeModal();
        this.$emit("delete", this.id, this.form );
      }
    },
    async changeReason () {
      if(this.form.reason == "01"){
        let response1 = await invoices_ws.getUUIDsWithRelation(this.invoice.receptor.rfc, this.invoice.cfdi.uuid); 

        if(response1.type == "success"){
          this.uuids = response1.data;

          if(this.uuids.length > 0){
            this.form.relation = this.uuids[0].uuid;
          }

          this.uuidsOptions = await this.$parseSelectOptionsOrdered(this.uuids, "uuid", "folio:uuid");
        }
      }
    },
    closeModal () {
      this.deleteModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      reason: {
        required
      },
      relation: {},
    }
  },
}
</script>