<template>
  <div>
    <CDataTable
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :items="items"
      :fields="is_new ? fields_with_actions : fields"
      :items-per-page="small ? 10 : 5"
      :dark="dark"
      pagination
    >
      <template #type="{item}"><td class="w200 text-center">{{ relation_type }}</td></template>
      <template #uuid="{item}"><td class="w200 text-center">{{ item.uuid ? item.uuid : item }}</td></template>
      <template #actions="{item, index}">
        <td class="table_actions text-center">
          <CButton color="danger" size="sm" @click="deleteModal(item, index)"><CIcon name="cil-trash"/></CButton>
        </td>
      </template>
    </CDataTable>

    <RemoveModal ref="deleteModal" @delete="deleteProcess"></RemoveModal>
  </div>
</template>

<script>
import RemoveModal from '../global/RemoveModal.vue'
import ws from '../../services/invoices';
import store from '../../store'

export default {
  name: 'RelationsTable',
  components: { RemoveModal },
  props: {
    is_new: true,
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    relation_type: {
      type: String,
      default: '04'
    },
  },
  data() {
    return {
      fields: [
        {key: 'type', label: 'Tipo de Relación'},
        {key: 'uuid', label: 'Folio Fiscal (UUID)'}
      ],
      fields_with_actions: [
        {key: 'type', label: 'Tipo de Relación'},
        {key: 'uuid', label: 'Folio Fiscal (UUID)'},
        {key: 'actions', label: 'Acciones', class: 'actions' }
      ],
      toasts: []
    }
  },
  methods: {
    deleteModal (item, index) {
      this.$refs.deleteModal.show("Eliminar CFDI Relacionado", index, item);
      this.item = item;
    },
    async deleteProcess(index) {
      this.$emit("deleteRelation", index);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
