import axios from 'axios';
import store from '../store';

export default {
    get: (tab,filters) => axios.post('invoices/list/'+tab,filters),
    getUUIDs: (rfc, uuids) => axios.post('invoices/uuids/'+rfc, uuids),
    getUUIDsByType: (rfc, type, uuids) => axios.post('invoices/uuids/'+type+'/'+rfc, uuids),
    getUUIDsWithBalance: (rfc, uuids) => axios.post('invoices/uuids/with-balance/'+rfc, uuids),
    getUUIDsWithCurrencyBalance: (rfc, currency, uuids) => axios.post('invoices/uuids/with-balance/'+rfc+"/"+currency, uuids),
    getUUIDsGreaterDate: (rfc, date) => axios.post('invoices/uuids/greater-date/'+rfc, {"date": date}),
    getUUIDsWithRelation: (rfc, uuid) => axios.post('invoices/uuids/with-relation/'+rfc, {"uuid": uuid}),
    getTotals: (filters) => axios.post('invoices/list/count',filters),
    show: (id) => axios.get('invoices/'+id+'/show'),
    downloadXML: (id) => axios.get('invoices/'+id+'/download-xml').then(function(response){
        if(response.type == "success"){
            window.open(axios.defaults.baseURL+"/s3?file="+response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadPDF: (id) => axios.get('invoices/'+id+'/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadCancelationPDF: (id) => axios.get('invoices/'+id+'/cancel/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadExcel: (filters) => axios.post('invoices/list/download-excel',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadZip: (filters) => axios.post('invoices/list/download-zip',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    show: (id) => axios.get('invoices/'+id+'/show'), 
    store: (data) => axios.post('invoices/store', data),
    update: (data) => axios.post('invoices/update', data),
    delete: (id) => axios.post('invoices/delete', id),
    cancel: (id, data) => axios.post('invoices/'+id+'/cancel', data),
    send: (id, data) => axios.post('invoices/'+id+'/send/mail', data),
    loadXml: (data) => axios.post('invoices/load-xml',data, {headers: {"Content-Type": "multipart/form-data",}}),
    preview: (data) => axios.post('invoices/preview', data).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');     
        }

        return response;
    }),
}