<template>
    <CModal :title="title" color="info" :show.sync="emailModal">
        <CRow>
          <CCol md="12">
            <CInput
              label="Para:"
              :lazy="false"
              :isValid="checkIfValid('to')"
              :value.sync="$v.form.to.$model"
              placeholder=""
              invalidFeedback="Este campo es obligatorio y debe ser un correo electrónico válida."
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CInput
              label="Enviar copia:"
              :lazy="false"
              :isValid="checkIfValid('cc')"
              :value.sync="$v.form.cc.$model"
              placeholder=""
              invalidFeedback="Este debe ser un correo electrónico válida."
            />
          </CCol>
        </CRow>    
        <CRow>
          <CCol md="12">
            <CTextarea
              label="Mensaje:"
              rows="5"
              :lazy="false"
              :isValid="checkIfValid('message')"
              :value.sync="$v.form.message.$model"
              placeholder="Escribe un mensaje..."
              invalidFeedback="Este debe ser un correo electrónico válida."
            />
          </CCol>
        </CRow>    
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="emailModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="emailModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="send" color="info">Enviar Correo</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"
import general_ws from '../../../services/general';
import invoices_ws from '../../../services/invoices';

export function manyMails(value) {
    if (value == '' || value == null || value === 'undefined') {
        return true;
    }
    else {
        return (/(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+/.test(value)) ? true : false;
    }
}

export default {
  name: 'EmailModal',
  props: {

  },
  data () {
    return {
      emailModal: false,
      title: "Enviar Correo",
      id: 0,
      invoice: false,
      name: 'Enviar Correo',
      isEdit: false,
      form: {
        to: '',
        cc: '',
        message: 'Estimado cliente, ponemos a su disposición su Factura CFDI.'
      },
      submitted: false,
      cancelationReasons: [],
      cancelationReasonsOptions: [],
      uuids: [],
      uuidsOptions: []
    }
  },
  mounted: async function() {
    let response1 = await general_ws.getCancelationReasons(); 

    if(response1.type == "success"){
      this.cancelationReasons = response1.data;

      this.cancelationReasonsOptions = await this.$parseSelectOptionsOrdered(this.cancelationReasons, "code", "code:name");
    }
  },
  methods: {
    show (invoice, type) {
      this.emailModal = true;

      let document_type = "Factura";
      if(type == "F"){
        document_type = "Factura";
      }
      else{
        if(type == "E"){
          document_type = "Nota de Crédito";
        }
        else{
          if(type == "P"){
            document_type = "Recibo de Pago";
          }
          else{
            if(type == "N"){
              document_type = "Nómina";
            }
          }
        }
      }

      this.title = 'Enviar '+document_type+' ( '+invoice.information.serial+""+invoice.information.folio+' )';
      this.id = invoice.id;
      this.name = invoice.information.serial+""+invoice.information.folio;
      this.invoice = invoice;

      this.isEdit = false;
      this.submitted = false;
      
      this.form = {
        to: this.invoice.email,
        cc: '',
        message: 'Estimado cliente, ponemos a su disposición su '+document_type+' (CFDI).'
      };

      this.submitted = false
      this.$v.$reset()
    },
    async send () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;
        
        this.closeModal();
        this.$emit("send", this.invoice, this.form );
      }
    },
    closeModal () {
      this.emailModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      to: {
        required,
        manyMails        
      },
      cc: {
        manyMails
      },
      message: {
        required
      }
    }
  },
}
</script>