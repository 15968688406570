<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="informationModal"
    >
      <CForm>
        <CRow>
          <CCol md="3">
            <CSelect
              label="Serie y Folio:"
              :isValid="checkIfValid('serial')"
              :value.sync="$v.form.serial.$model"
              :options="serialsOptions"
              @change="changeSerial"
            />
          </CCol>
          <CCol md="3">
            <div role="group" class="form-group">
              <label v-show="type != 'payment'"> Fecha:</label>
              <label v-show="type == 'payment'"> Fecha de Pago:</label>
              <v-date-picker
                class="date-picker"
                v-model="$v.form.date.$model"
                :isValid="checkIfValid('date')"
                locale="es-MX" size="sm"
                :maxDate="optionsDate.maxDate"
                :minDate="type != 'payment' ? optionsDate.minDate : false"
              />
            </div>
          </CCol>
          <CCol :md="$v.form.currency_code.$model == 'MXN' ? 6 : 4">
            <CSelect
              label="Moneda:"
              :isValid="checkIfValid('currency_code')"
              :value.sync="$v.form.currency_code.$model"
              :options="currenciesOptions"
              @change="changeCurrency"
            />
          </CCol>
          <CCol md="2" v-show="$v.form.currency_code.$model != 'MXN'">
            <CInput
                label="TC:"
                :lazy="false"
                :value.sync="$v.form.exchange_rate.$model"
                placeholder=""
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6" v-show="type != 'payment'">
            <CSelect
              label="Método de Pago:"
              :isValid="checkIfValid('payment_method_code')"
              :value.sync="$v.form.payment_method_code.$model"
              :options="paymentMethodsOptions"
              @change="changePaymentMethod"
            />
          </CCol>
          <CCol md="6">
            <CSelect
              label="Forma de Pago:"
              :isValid="checkIfValid('payment_form_code')"
              :value.sync="$v.form.payment_form_code.$model"
              :options="paymentFormsOptions"
              @change="changePaymentForm"
            />
          </CCol>
          <CCol md="6" v-show="type == 'payment'">
              <CInput
                label="No. Operación:"
                :lazy="false"
                :value.sync="$v.form.no_operation.$model"
                :isValid="checkIfValid('no_operation')"
                placeholder=""
                autocomplete="no_operation"
                invalidFeedback="Este campo debe ser un valor numérico."
              />
          </CCol>
        </CRow>
        <CRow v-show="type != 'payment'">
          <CCol md="12">
            <CTextarea
              label="Observaciones:"
              rows="5"
              :lazy="false"
              :isValid="checkIfValid('comments')"
              :value.sync="$v.form.comments.$model"
            />
          </CCol>
        </CRow>
      </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import serials_ws from '../../../services/serials';
import currencies_ws from '../../../services/currencies';
import general_ws from '../../../services/general';

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss"));
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(2, 'day');

export default { 
  name: 'InformationModal',
  props: {
    type: ''
  },
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      informationModal: false,
      title: "Información de la Factura", 
      color: "info",
      isEdit: false,
      form: {},
      optionsDate: {
        maxDate: today.toDate(),
        minDate: today2.toDate()
      },
      optionsPaymentDate: {
        maxDate: today.toDate()
      },
      submitted: false,
      serials: [],
      serialsOptions: [],
      payment_forms: [],
      paymentFormsOptions: [],
      payment_methods: [],
      paymentMethodsOptions: [],
      currencies: [],
      currenciesOptions: [],
      document_type: 'income'
    }
  },
  mounted: async function() {
    this.document_type = this.type == '' ? 'income' : this.type;

    let response1 = await serials_ws.getByType(this.document_type); 

    if(response1.type == "success"){
      this.serials = response1.data;

      this.form.serial = response1.data[0].serial;
      this.form.folio = response1.data[0].current;
      this.form.serial_id = response1.data[0].id;
    }
 
    let response2 = await currencies_ws.get(); 

    if(response2.type == "success"){
      this.currencies = response2.data;
    }

    let response3 = await general_ws.getPaymentForms(); 

    if(response3.type == "success"){
      this.payment_forms = response3.data;
    }

    let response4 = await general_ws.getPaymentMethods(); 

    if(response4.type == "success"){
      this.payment_methods = response4.data;
    }

    this.serialsOptions = await this.$parseSelectOptionsOrdered(this.serials, "serial", "serial|current");
    this.paymentFormsOptions = await this.$parseSelectOptionsOrdered(this.payment_forms, "code", "code:name");
    this.paymentMethodsOptions = await this.$parseSelectOptionsOrdered(this.payment_methods, "code", "code:name");
    this.currenciesOptions = await this.$parseSelectOptionsOrdered(this.currencies, "code", "code:name");
  },
  methods: {
    async changeSerial () {
      let serial = await this.$findSerialByName(this.serials, this.form.serial);

      this.form.serial = serial.serial;
      this.form.folio = serial.current;
      this.form.serial_id = serial.id;
    },
    async changeCurrency () {
      let currency = await this.$findElementByCode(this.currencies, this.form.currency_code);

      this.form.currency_code = currency.code;
      this.form.exchange_rate = currency.exchange_rate;
    },
    async changePaymentForm () {
      let payment_form = await this.$findElementByCode(this.payment_forms, this.form.payment_form_code);

      this.form.payment_form_code = payment_form.code;
      this.form.payment_form_name = payment_form.name;
    },
    async changePaymentMethod () {
      let payment_method = await this.$findElementByCode(this.payment_methods, this.form.payment_method_code);

      this.form.payment_method_code = payment_method.code;
      this.form.payment_method_name = payment_method.name;
    },
    openModal (data) {
      this.informationModal = true;
      this.color = "info";
      this.title = 'Editar Información';
      this.isEdit = false;
      this.submitted = false;

      let date = data.date;

      this.form = {
          serial: data.serial,
          folio: data.folio,
          serial_id: data.serial_id,
          date: date.toDate(),
          payment_form_code: data.payment_form.code,
          payment_form_name: data.payment_form.name,
          payment_method_code: data.payment_method.code,
          payment_method_name: data.payment_method.name,
          no_operation: data.no_operation,
          currency_code: data.currency.code,
          currency_name: data.currency.name,
          exchange_rate: data.currency.exchange_rate,
          comments: data.comments
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("updateInformation", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.informationModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      serial: {
        required
      },
      folio: false,
      payment_form_code: {
        required
      },
      payment_method_code: {
        required
      },
      currency_code: {
        required
      },
      date: {
        required
      },
      no_operation: false,
      exchange_rate: false,
      comments: false
    }
  },
}
</script>